import { ApiConnector } from "../apiConnector";
import { parseDatetimeToLocal } from "../utils";
import { DataAdd } from "./DataAdd";

export class AddAnyData extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = [
      "heart rate",
      "blood pressure systolic",
      "blood pressure diastolic",
      "SpO2",
      "NT-proBNP",
      "BNP",
      "urea",
      "creatinine",
      "hemoglobin",
      "hematocrit",
      "weight",
      "dry weight",
      "temperature",
      "respiratory rate",
      "ECG rhythm",
      "ECG premature contractions",
      "leg edema",
      "jugular venous distention",
      "LVEF",
      "E/A",
      "E/e' average",
      "TR velocity",
      "TR gradient",
      "LAVi",
      "elevated LV filling pressure",
      "stroke volume on echo",
      "AoS",
      "AoR",
      "MR",
      "MS",
      "TR",
      "PuR",
      "RVSP",
      "B-lines on lung-ultrasound",
      "pleural effusion",
      "ascites",
      "RHC right atrial pressure mean",
      "RHC ventricular pressure systolic",
      "RHC ventricular pressure diastolic",
      "RHC pulmonary capillary wedge pressure",
      "RHC pulmonary artery pressure systolic",
      "RHC pulmonary artery pressure diastolic",
      "RHC pulmonary artery pressure mean",
      "RHC stroke volume - Fick",
      "RHC stroke volume - TD",
      "RHC stroke volume index",
      "RHC cardiac output - Fick",
      "RHC cardiac output - TD",
      "RHC cardiac index - Fick",
      "RHC cardiac index - TD",
      "RHC pulmonary vascular resistance - Fick",
      "RHC pulmonary vascular resistance - TD",
      "RHC pulmonary vascular resistance index",
      "RHC total pulmonary resistance",
      "RHC pulmonary arterial compliance",
      "RHC transpulmonary gradient",
      "RHC diastolic transpulmonary gradient",
      "RHC mixed venous oxygen saturation",
      "RHC arterial oxygen saturation",
      "RHC systemic blood pressure systolic",
      "RHC systemic blood pressure diastolic",
      "elevated LV filling pressure final outcome",
    ];
    this.initFields = [
      "measurement_datetime",
      "measurement_type",
      "measurement_unit",
    ];
    this.desc = "Add (almost) any additional data";
    this.defaultDateTimeName = "Now";
    this.defaultDateTimeFields = ["measurement_datetime"];
    this.dateTimeFields = ["measurement_datetime"];
    this.bodyField = "medical_data";
    this.endpoint = "medicaldata/bulk";
    this.reloadToTab = "any";
  }

  async initEmpty() {
    this.defaultDateTime = parseDatetimeToLocal(new Date());
    this.addingList = [];
  }

  addItem() {
    this.addingList.push({
      measurement_datetime: this.defaultDateTime,
      measurement_type: null,
      measurement_value: null,
      measurement_unit: null,
    });
  }

  customSanitize(field) {
    field.measurement_unit = this.getUnits(field.measurement_type, true);
    return field;
  }
}
