import { ApiConnector } from "../apiConnector";
import { DataAdd } from "./DataAdd";

export class AddDiags extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.initFields = ["diagnosis_name", "diagnosed_at", "diagnosis_value"];
    this.desc = "Comorbidities for HF study";
    this.bodyField = "diagnoses";
    this.endpoint = "hf/comorbidities";
    this.defaultDateTimeName = "Unset (date not required)";
    this.defaultDateTimeFields = ["diagnosed_at"];
    this.reloadToTab = "diags";
  }

  async initEmpty() {
    this.defaultDateTime = null;
    var empty = [];
    for (const variable of Object.keys(this.patient.diagOptions)) {
      const temp = {
        diagnosis_name: variable,
        diagnosed_at: this.defaultDateTime,
        diagnosis_value: this.patient.diagOptions[variable][0],
      };
      empty.push(temp);
    }
    this.addingList = empty;
  }

  addItem() {
    this.addingList.push({
      diagnosis_name: null,
      diagnosed_at: this.defaultDateTime,
    });
  }

  async postBulk() {
    this.addingList = this.addingList.filter(
      (diag) => diag.diagnosis_value !== "No",
    );
    await super.postBulk();
  }
}
