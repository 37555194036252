import { ApiConnector } from "../apiConnector";
import { parseDatetimeToLocal } from "../utils";
import { DataAdd } from "./DataAdd";

export class AddPPG extends DataAdd {
  ppgFile = new FormData();

  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.desc = "PPG data from SmartCare device (others not tested)";
    this.endpoint = "";
    this.dateTimeFields = ["measurement_datetime"];
    this.defaultDateTime = null;
    this.reloadToTab = "ppg";
  }

  async initEmpty() {
    this.addingList = {
      measurement_datetime: this.defaultDateTime,
      measurement_condition: null,
      measurement_device: "SmartCare BM2000A",
      measurement_origin: "finger",
      measurement_mode: 1,
      quality_flag: 1,
      comment: "",
    };
  }

  uploadFile(event) {
    const file = event.target.files[0];
    this.ppgFile.set("ppg_file", file);
  }

  objectToQueryString(obj) {
    return Object.keys(obj)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`,
      )
      .join("&");
  }

  async post() {
    this.addingList.patient_id = this.patient.patientId;
    for (const key in this.addingList) {
      if (
        this.dateTimeFields.includes(key) &&
        this.addingList.hasOwnProperty(key)
      ) {
        var datetime = this.addingList[key];
        this.addingList[key] = new Date(
          parseDatetimeToLocal(datetime),
        ).toISOString();
      }
    }
    const queryParams = this.objectToQueryString(this.addingList);

    const endpointRoute = `ppg/smartcare?${queryParams}`;
    const headers = await this.api.postPutHeaders();
    delete headers["Content-Type"];
    try {
      const response = await this.api.request(
        endpointRoute,
        "POST",
        headers,
        this.ppgFile,
        false,
      );
      if (response != null) {
        window.PineconeRouter.context.navigate(
          `/patient/${this.patient.patientId}/${this.reloadToTab}`,
        );
        window.location.reload();
      }
    } catch (error) {
      console.error("POST error:", error);
    }
  }
}
