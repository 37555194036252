import { createMemo } from "solid-js";
import { DataModel } from "../../../dataModels/DataModel";

type Sort = "asc" | "desc";

export function HeaderSort<T extends object>(props: {
  model: DataModel<T>;
  attr: string;
  name?: string;
}) {
  const sort = createMemo((): Sort | undefined => {
    const id = props.model.sortBy.indexOf(props.attr);

    if (props.model.sortDesc?.[id] === null) {
      return undefined;
    } else if (props.model.sortDesc?.[id] === false) {
      return "asc";
    } else {
      return "desc";
    }
  });

  return (
    <>
      <b>{props.name ?? "Sort"}</b>
      <button
        onClick={() => {
          props.model.rotateSortState(props.attr);
        }}
        class="sort-btn"
      >
        {sort() === "asc" ? "\u25B2" : sort() === "desc" ? "\u25BC" : "\u25EF"}
      </button>
    </>
  );
}
