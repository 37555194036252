import { ApiConnector } from "../apiConnector";
import { DataAdd } from "./DataAdd";
import { ListDataField } from "./ListDataField";

export class AddPhysician extends DataAdd {
  phoneList: ListDataField;
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.addingList = {
      surname: null,
      given_name: null,
      titles: null,
      realm: 1,
      email: null,
      phone: [],
      connected_user_uuid: null,
      clinics_uuids: [],
      patient_ids: [],
    };

    this.phoneList = new ListDataField();
  }

  async post(
    phones: string[] = [],
    clinics: string[] = [],
    patients: number[] = [],
    user_uuid: string = null,
  ) {
    this.addingList["phone"] = phones;
    this.addingList["clinics_uuids"] = clinics;
    this.addingList["patient_ids"] = patients;
    if (user_uuid != null) {
      this.addingList["connected_user_uuid"] = user_uuid;
    }
    try {
      const response = await this.api.post("physicians", this.addingList);
      if (response != null) {
        window.PineconeRouter.context.navigate("/admin/physicians");
        location.reload();
      }
    } catch (error) {
      console.error("POST error:", error);
    }
  }
}
