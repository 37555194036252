import Alpine from "alpinejs";
import { effect, reactive, stop, toRaw } from "@vue/reactivity";
import { enableExternalSource } from "solid-js";

export function initSolid() {
  // Alpine uses @vue/reactivity internally. But it has its own instance and we cannot
  // access it, so we pass it our own @vue/reactivity instance instead.
  Alpine.setReactivityEngine({ reactive, effect, release: stop, raw: toRaw });

  // We can use Solid with @vue/reactivity thanks to this snippet
  enableExternalSource((fn, trigger) => {
    let effectFn = () => {};
    const ef = effect(() => effectFn(), { scheduler: () => trigger() });

    return {
      track: (x) => {
        let next: unknown;
        effectFn = () => (next = fn(x));
        ef();
        return next;
      },
      dispose: () => {
        stop(ef);
      },
    };
  });
}
