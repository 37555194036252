import { createMemo } from "solid-js";
import { Patient } from "../patient";
import { floatRounding } from "../utils";
import { CellEditButtons } from "./uiKit/table/CellEditButtons";
import { CellSelectEditable } from "./uiKit/table/CellSelectEditable";
import { CellTags } from "./uiKit/table/CellTags";
import { CellTextEditable } from "./uiKit/table/CellTextEditable";
import { HeaderSelectFilter } from "./uiKit/table/HeaderSelectFilter";
import { HeaderSort } from "./uiKit/table/HeaderSort";
import { Table } from "./uiKit/table/Table";

export function DRITable(props: { patient: Patient }) {
  const model = createMemo(() => props.patient.driData);

  return (
    <Table
      model={model()}
      columns={[
        {
          header1: "Measured",
          header2: <HeaderSort attr="measurement_datetime" model={model()} />,
          cell: (item) => item.measurement_datetime,
        },
        {
          header1: "Type",
          header2: (
            <HeaderSelectFilter
              attr="measurement_type"
              model={model()}
              name="Type"
            />
          ),
          cell: (item) => item.measurement_type,
        },
        {
          header1: "Value",
          cell: (item) => floatRounding(item.measurement_value),
        },
        {
          header1: "Unit",
          cell: (item) => item.measurement_unit,
        },
        {
          header1: "Seerlinq algorithm",
          header2: (
            <>
              <HeaderSelectFilter
                attr="seerlinq_algorithm"
                model={model()}
                name="Algorithm"
              />
              <HeaderSelectFilter
                attr="seerlinq_algorithm_version"
                model={model()}
                name="Version"
              />
            </>
          ),
          cell: (item) =>
            item.seerlinq_algorithm + " " + item.seerlinq_algorithm_version,
        },
        {
          header1: "Quality flag",
          header2: (
            <HeaderSelectFilter
              attr="seerlinq_measurement_quality_flag"
              model={model()}
              name="Flag"
              values={model().algoFlags}
            />
          ),
          cell: (item, index) => (
            <CellSelectEditable
              attr="seerlinq_measurement_quality_flag"
              index={index}
              model={model()}
              values={model().algoFlags}
            />
          ),
        },
        {
          header1: "Tags",
          header2: (
            <HeaderSelectFilter attr="tags" model={model()} name="Tags" />
          ),
          cell: (item) => <CellTags tags={item.tags} />,
        },
        {
          header1: "Comment",
          cell: (item, index) => (
            <CellTextEditable
              attr="comment"
              index={index}
              model={model()}
              width={50}
            />
          ),
        },
        {
          header1: "Edit",
          cell: (item, index) => (
            <CellEditButtons
              model={model()}
              index={index}
              onConfirm={(editedField) => {
                props.patient.updateItem("computed", item.uuid, editedField);
              }}
            />
          ),
        },
      ]}
    />
  );
}
