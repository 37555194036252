import { createMemo, For, Show } from "solid-js";
import { DataModel } from "../../../dataModels/DataModel";

export function CellSelectEditable<
  T extends object,
  V extends string | number,
>(props: {
  model: DataModel<T>;
  attr: string;
  index: number;
  values: Record<V, string>;
  clickToEdit?: boolean;
  onChange?: (editedField: object) => void;
}) {
  const item = createMemo(() => props.model.paginatedData[props.index]);
  const value = createMemo(() => item()[props.attr]);
  const editing = createMemo(
    () =>
      props.model.editingId === props.index &&
      props.model.canEdit(props.attr, item()),
  );

  return (
    <div>
      <Show
        when={editing()}
        fallback={
          <span
            onClick={() => {
              if (props.clickToEdit) {
                props.model.startEditing(props.index);
              }
            }}
            style={{ cursor: props.clickToEdit ? "pointer" : undefined }}
          >
            {props.values[value()]}
          </span>
        }
      >
        <select
          value={props.model.editedField?.[props.attr]}
          onChange={(event) => {
            if (!props.model.editedField) {
              return;
            }

            const val =
              value() === "number"
                ? parseInt(event.target.value)
                : event.target.value;
            props.model.editedField[props.attr] = val;
          }}
        >
          <For each={Object.keys(props.values)}>
            {(val) => <option value={val}>{props.values[val]}</option>}
          </For>
        </select>

        <Show when={editing() && props.clickToEdit}>
          {" "}
          <button
            onClick={() => {
              props.onChange?.(props.model.editedField);
              props.model.stopEditing();
            }}
          >
            {"\u2620"}
          </button>{" "}
          <button onClick={() => props.model.stopEditing()}>X</button>
        </Show>
      </Show>
    </div>
  );
}
