import { createMemo, Show } from "solid-js";
import { DataModel } from "../../../dataModels/DataModel";

export function CellDateEditable(props: {
  model: DataModel;
  index: number;
  attr: string;
  type: "datetime-local" | "date";
}) {
  const item = createMemo(() => props.model.paginatedData[props.index]);

  return (
    <Show
      when={
        props.model.editingId === props.index &&
        props.model.canEdit(props.attr, item())
      }
      fallback={item()[props.attr]}
    >
      <input
        type={props.type}
        value={props.model.editedField?.[props.attr]}
        onChange={(event) => {
          props.model.editedField[props.attr] = event.target.value;
        }}
      />
    </Show>
  );
}
