import { createMemo } from "solid-js";
import { ApiConnector } from "../apiConnector";
import { Patient } from "../patient";
import { CellEditButtons } from "./uiKit/table/CellEditButtons";
import { CellTextEditable } from "./uiKit/table/CellTextEditable";
import { HeaderSelectFilter } from "./uiKit/table/HeaderSelectFilter";
import { HeaderSort } from "./uiKit/table/HeaderSort";
import { Table } from "./uiKit/table/Table";

export function EventsTable(props: { patient: Patient; api: ApiConnector }) {
  const model = createMemo(() => props.patient.events);

  return (
    <Table
      model={model()}
      columns={[
        {
          header1: "Date",
          header2: <HeaderSort model={model()} attr="event_date" />,
          cell: (item) => item.event_date,
        },
        {
          header1: "Timestamp",
          header2: <HeaderSort model={model()} attr="event_timestamp" />,
          cell: (item) => item.event_timestamp,
        },
        {
          header1: "Type",
          header2: (
            <HeaderSelectFilter
              attr="event_type"
              model={model()}
              name="Type"
              values={model().eventTypes}
            />
          ),
          cell: (item) => model().eventTypes[item.event_type],
        },
        {
          header1: "Description",
          cell: (item, index) => (
            <CellTextEditable
              attr="event_description"
              index={index}
              model={model()}
              width={120}
            />
          ),
        },
        {
          header1: "Comment",
          cell: (item, index) => (
            <CellTextEditable
              attr="event_comment"
              index={index}
              model={model()}
              width={50}
            />
          ),
        },
        {
          header1: "Edit",
          cell: (item, index) => (
            <CellEditButtons
              model={model()}
              index={index}
              onConfirm={(editedField) => {
                props.patient.updateItem("events", item.uuid, editedField);
              }}
            />
          ),
        },
        props.api.amIAdmin && {
          header1: "Delete",
          cell: (item) => (
            <button
              onClick={() => props.patient.deleteItem("events", item.uuid)}
            >
              Delete
            </button>
          ),
        },
      ]}
    />
  );
}
