import clsx from "clsx";
import { Show } from "solid-js";
import { ApiConnector } from "../apiConnector";
import { Patients } from "../dataModels/Patients";
import { consentOk } from "../dataUtils";
import {
  patientStates,
  patientStatuses,
  seerlinqRealms,
  seerlinqStudies,
} from "../enumToText";
import { CellSelectEditable } from "./uiKit/table/CellSelectEditable";
import { CellTextEditable } from "./uiKit/table/CellTextEditable";
import { HeaderSelectFilter } from "./uiKit/table/HeaderSelectFilter";
import { HeaderSort } from "./uiKit/table/HeaderSort";
import { HeaderTextFilter } from "./uiKit/table/HeaderTextFilter";
import { QuickLookButton } from "./uiKit/table/QuickLookButton";
import { Table } from "./uiKit/table/Table";

export function PatientsTable(props: { model: Patients; api: ApiConnector }) {
  return (
    <Show when={props.model.firstPage}>
      <h2>Patients</h2>
      <span>
        We have <strong>{props.model.apiTotalItems}</strong> patient(s) in the
        database.
      </span>
      <Show when={!props.model.fullyLoaded}>
        <small>
          Loading from API on background...
          <span>{props.model.apiPage}</span>/{" "}
          <span>{props.model.apiTotalPages}</span>
        </small>
      </Show>

      <br />
      <br />

      <Table
        model={props.model}
        columns={[
          {
            header1: "Patient HF study ID",
            header2: <HeaderSort model={props.model} attr="patient_id" />,
            header3: (
              <HeaderTextFilter
                model={props.model}
                placeholder="ID filter..."
              />
            ),
            cell: (item) => {
              const disabled = !consentOk(item) && !props.api.amIAdmin;

              return (
                <strong>
                  <a
                    class={clsx("patient-link", disabled && "disabled-link")}
                    href={!disabled ? `/patient/${item.patient_id}` : "#"}
                  >
                    {item.patient_id}
                  </a>
                </strong>
              );
            },
          },
          props.api.amILevel3 && {
            header1: "Risk flag",
            header2: (
              <HeaderSelectFilter
                name="Flag"
                attr="patient_state"
                model={props.model}
                values={patientStates}
              />
            ),
            header3: "(Click to change)",
            cell: (item, index) => (
              <CellSelectEditable
                model={props.model}
                attr="patient_state"
                index={index}
                values={patientStates}
                onChange={(editedField) => {
                  props.model.editRisk(item.patient_id, editedField);
                }}
                clickToEdit
              />
            ),
          },
          {
            header1: "Added",
            header2: <HeaderSort model={props.model} attr="created_at" />,
            cell: (item) => item.created_at,
          },
          {
            header1: "Date of birth",
            cell: (item) => item.date_of_birth,
          },
          {
            header1: "Realm",
            header2: (
              <HeaderSelectFilter
                name="Realm"
                attr="realm"
                model={props.model}
                values={seerlinqRealms}
              />
            ),
            cell: (item) => seerlinqRealms[item.realm],
          },
          {
            header1: "Seerlinq studies",
            header2: (
              <HeaderSelectFilter
                name="Studies"
                attr="patient_study"
                model={props.model}
                values={seerlinqStudies}
              />
            ),
            cell: (item) => seerlinqStudies[item.patient_study],
          },
          {
            header1: "Status",
            header2: (
              <HeaderSelectFilter
                name="Status"
                attr="patient_status"
                model={props.model}
                values={patientStatuses}
              />
            ),
            cell: (item) => patientStatuses[item.patient_status],
          },
          props.api.amILevel3 && {
            header1: "Monitoring note",
            header3: "(Click to change)",
            cell: (item, index) => (
              <CellTextEditable
                model={props.model}
                attr="monitoring_note"
                index={index}
                onChange={(editedField) => {
                  props.model.editRisk(item.patient_id, editedField);
                }}
                clickToEdit
              />
            ),
          },
          props.api.amILevel3 && {
            header1: "Last PPG",
            cell: (item) => item.last_ppg,
          },
          props.api.amILevel3 && {
            header1: "HeartCore \u2665",
            cell: (item) => (
              <QuickLookButton
                disabled={item.patHeartCore.processing || !item.canRunHC}
                processing={item.patHeartCore.processing}
                onClick={() => {
                  if (item.patHeartCore.resultUrl) {
                    window.open(item.patHeartCore.resultUrl, "_blank");
                  } else {
                    item.patHeartCore.submit([item.patient_id]);
                  }
                }}
              >
                <Show
                  when={item.patHeartCore.resultUrl}
                  fallback="DRI HeartCore"
                >
                  View HeartCore
                </Show>
              </QuickLookButton>
            ),
          },
        ]}
      />
    </Show>
  );
}
