import { ApiConnector } from "../apiConnector";
import { parseDatetimeToLocal } from "../utils";
import { DataAdd } from "./DataAdd";

export class AddRHC extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = [
      "RHC right atrial pressure mean",
      "RHC ventricular pressure systolic",
      "RHC ventricular pressure diastolic",
      "RHC pulmonary capillary wedge pressure",
      "RHC pulmonary artery pressure systolic",
      "RHC pulmonary artery pressure diastolic",
      "RHC pulmonary artery pressure mean",
      "RHC stroke volume - Fick",
      "RHC stroke volume - TD",
      "RHC stroke volume index",
      "RHC cardiac output - Fick",
      "RHC cardiac output - TD",
      "RHC cardiac index - Fick",
      "RHC cardiac index - TD",
      "RHC pulmonary vascular resistance - Fick",
      "RHC pulmonary vascular resistance - TD",
      "RHC pulmonary vascular resistance index",
      "RHC total pulmonary resistance",
      "RHC pulmonary arterial compliance",
      "RHC transpulmonary gradient",
      "RHC diastolic transpulmonary gradient",
      "RHC mixed venous oxygen saturation",
      "RHC arterial oxygen saturation",
      "RHC systemic blood pressure systolic",
      "RHC systemic blood pressure diastolic",
    ];
    this.initFields = [
      "measurement_datetime",
      "measurement_type",
      "measurement_unit",
      "study",
    ];
    this.desc = "Right Heart Catheterization exams";
    this.dateTimeFields = ["measurement_datetime"];
    this.defaultDateTimeName = "Now";
    this.defaultDateTimeFields = ["measurement_datetime"];

    // constants
    this.required = [
      "RHC right atrial pressure mean",
      "RHC pulmonary capillary wedge pressure",
      "RHC pulmonary artery pressure mean",
      "RHC mixed venous oxygen saturation",
      "RHC systemic blood pressure systolic",
      "RHC systemic blood pressure diastolic",
    ];
    this.requiredEitherOr = [
      "RHC stroke volume - Fick",
      "RHC stroke volume - TD",
      "RHC cardiac output - Fick",
      "RHC cardiac output - TD",
    ];
    this.bodyField = "medical_data";
    this.endpoint = "hf/exams/rhc";
    this.reloadToTab = "exams";
  }

  async initEmpty() {
    this.defaultDateTime = parseDatetimeToLocal(new Date());
    var empty = [];
    for (const variable of this.variables) {
      const temp = {
        measurement_datetime: this.defaultDateTime,
        measurement_type: variable,
        measurement_unit: this.getUnits(variable, true),
        study: "HF validation",
      };
      empty.push(temp);
    }
    this.addingList = empty;
  }
}
