import { ApiConnector } from "../apiConnector";
import { DataAdd } from "./DataAdd";

export class AddThresholds extends DataAdd {
  // constants
  threshTables = {
    medicaldata: "Medical data (Manual)",
    symptoms: "Symptoms (Manual)",
    ppg_derived: "PPG-derived (from Oximeter)",
    sq_computed: "SQ-computed (our algorithm)",
  };
  threshTypes = ["low", "high"];
  threshTriggers = { value_based: "Value-based", change_based: "Change-based" };
  changeTypes = ["increase", "decrease"];
  changeToThreshType = { increase: "high", decrease: "low" };
  availableThresholds = {
    "diastolic reserve index (median)": "sq_computed",
    "blood pressure systolic": "medicaldata",
    "blood pressure diastolic": "medicaldata",
    "heart rate": "medicaldata",
    "fatigue score": "symptoms",
    "shortness of breath": "symptoms",
    "HR: mean": "ppg_derived",
    "SpO2: mean": "ppg_derived",
  };

  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = [];
    this.initFields = ["threshold_variable", "threshold_type"];
    this.desc = "Patient's thresholds";
    this.endpoint = "thresholds";
    this.reloadToTab = "thresholds";
  }
  async initEmpty() {
    var empty = [];
    const temp = {
      threshold_variable: null,
      threshold_variable_table: null,
      threshold_trigger: null,
      threshold_type: null,
      threshold_settings: null,
    };
    empty.push(temp);
    this.addingList = empty;
  }

  addItem() {
    this.addingList.push({
      threshold_variable: null,
      threshold_variable_table: null,
      threshold_trigger: null,
      threshold_type: null,
      threshold_settings: null,
    });
  }

  initSettings(thresh: any) {
    if (thresh.threshold_trigger === "value_based") {
      thresh.threshold_settings = {
        threshold_trigger: "value_based",
        at_least: null,
        out_of: null,
      };
    } else {
      thresh.threshold_type = null;
      thresh.threshold_settings = {
        threshold_trigger: "change_based",
        change_type: null,
        look_back_days: null,
        maximum_measurements: null,
      };
    }
    return thresh;
  }

  async postBulk() {
    for (let i = 0; i < this.addingList.length; i++) {
      this.addingList[i].patient_id = this.patient.patientId;
      this.addingList[i].threshold_variable_table =
        this.availableThresholds[this.addingList[i]["threshold_variable"]];
      if (
        this.addingList[i].threshold_trigger === "change_based" &&
        this.addingList[i].threshold_settings.change_type === "decrease"
      ) {
        if (parseFloat(this.addingList[i].threshold_value) > 0) {
          this.addingList[i].threshold_value =
            0 - parseFloat(this.addingList[i].threshold_value);
        }
      }
    }
    // POST one by one
    for (let i = this.addingList.length - 1; i >= 0; i--) {
      try {
        const response = await this.api.post(this.endpoint, this.addingList[i]);
        if (response != null) {
          this.addingList.splice(i, 1);
        }
      } catch (error) {
        console.error("POST error:", error);
      }
    }
    window.PineconeRouter.context.navigate(
      `/patient/${this.patient.patientId}/${this.reloadToTab}`,
    );
    window.location.reload();
  }
}
