import { createMemo, Show } from "solid-js";
import { ApiConnector } from "../apiConnector";
import { Patient } from "../patient";
import { floatRounding } from "../utils";
import { CellEditButtons } from "./uiKit/table/CellEditButtons";
import { CellTextEditable } from "./uiKit/table/CellTextEditable";
import { HeaderSelectFilter } from "./uiKit/table/HeaderSelectFilter";
import { Table } from "./uiKit/table/Table";

export function ThresholdsTable(props: {
  patient: Patient;
  api: ApiConnector;
}) {
  const model = createMemo(() => props.patient.thresholds);

  return (
    <Table
      model={model()}
      columns={[
        {
          header1: "Variable table",
          header2: (
            <HeaderSelectFilter
              attr="threshold_variable_table"
              model={model()}
              name="Table"
              values={model().threshTables}
            />
          ),
          cell: (item) => model().threshTables[item.threshold_variable_table],
        },
        { header1: "Variable", cell: (item) => item.threshold_variable },
        {
          header1: "Trigger",
          header2: (
            <HeaderSelectFilter
              attr="threshold_trigger"
              model={model()}
              name="Trigger"
              values={model().threshTriggers}
            />
          ),
          cell: (item) => model().threshTriggers[item.threshold_trigger],
        },
        {
          header1: "Type",
          header2: (
            <HeaderSelectFilter
              attr="threshold_type"
              model={model()}
              name="Type"
            />
          ),
          cell: (item) => item.threshold_type,
        },
        {
          header1: "Value",
          cell: (item, index) => (
            <CellTextEditable
              attr="threshold_value"
              index={index}
              model={model()}
              displayValue={floatRounding(item.threshold_value, 0)}
              width={50}
            />
          ),
        },
        { header1: "How", cell: (item) => model().settingsString(item) },
        {
          header1: "Comment",
          cell: (item, index) => (
            <CellTextEditable
              attr="threshold_comment"
              index={index}
              model={model()}
              width={50}
            />
          ),
        },
        {
          header1: "Edit",
          cell: (item, index) => (
            <>
              <Show when={!item.isDefault}>
                <CellEditButtons
                  model={model()}
                  index={index}
                  onConfirm={(editedField) => {
                    props.patient.updateItem(
                      "thresholds",
                      item.uuid,
                      editedField,
                    );
                  }}
                />
              </Show>
              <Show when={item.isDefault}>&nbsp;(cannot edit)</Show>
            </>
          ),
        },
      ]}
    />
  );
}
