import { ApiConnector } from "../apiConnector";
import { parseDatetimeToLocal } from "../utils";
import { DataAdd } from "./DataAdd";

export class AddEcho extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = [
      "LVEF",
      "E/A",
      "E/e' average",
      "TR velocity",
      "TR gradient",
      "LAVi",
      "elevated LV filling pressure",
      "stroke volume on echo",
      "AoS",
      "AoR",
      "MR",
      "MS",
      "TR",
      "PuR",
      "RVSP",
      "B-lines on lung-ultrasound",
      "pleural effusion",
      "ascites",
    ];
    this.initFields = [
      "measurement_datetime",
      "measurement_type",
      "measurement_unit",
      "study",
    ];
    this.desc = "ECHO exams";
    this.dateTimeFields = ["measurement_datetime"];
    this.defaultDateTimeName = "Now";
    this.defaultDateTimeFields = ["measurement_datetime"];
    this.bodyField = "medical_data";
    this.endpoint = "hf/exams/echo";
    this.reloadToTab = "exams";
    this.requiredFields = [
      "measurement_datetime",
      "measurement_type",
      "measurement_value",
    ];
  }

  async initEmpty() {
    this.defaultDateTime = parseDatetimeToLocal(new Date());
    var empty = [];
    for (const variable of this.variables) {
      const temp = {
        measurement_datetime: this.defaultDateTime,
        measurement_type: variable,
        measurement_unit: this.getUnits(variable, true),
        study: "HF validation",
      };
      empty.push(temp);
    }
    this.addingList = empty;
  }
}
