import { toRaw } from "@vue/reactivity";
import { dateTimeOrNull, parseDatetimeToLocal } from "../utils";

export class DataModel<T extends object = any> {
  htmlData: any[] = [];
  rawData: T[] = [];
  hasHistory: boolean = false;
  showingHistory: boolean = false;

  // sorting
  sortBy: string[] = [];
  initSort: boolean[] = [];
  sortByNames: string[] = [];
  canSwitchSortOrder: boolean = false;
  sortDesc: boolean[];

  // filtering
  filteringAttrs: string[] = [];
  filteringListAttrs: string[] = [];
  filteringIntAttrs: string[] = [];
  defaultFilter: object = null;
  fieldMapper: object = {};
  uniques: Record<string, any[]> = {};
  filter: Record<string, any[] | boolean> = {};

  queryFilter: string = "";
  queryKey: string = null;

  // field types
  stringFields: string[] = [];
  dateFields: string[] = [];
  dateTimeFields: string[] = [];

  // editing
  editable: string[] = [];
  editingId: number;
  editedField: any;

  // pagination
  rowsPerPage: number = 25;
  rowsPerPageOptions: number[] = [10, 25, 50, 100];
  currentPage: number = 1;
  totalPages: number = null;

  constructor(
    public data: T[],
    public canShowHistory: boolean = false,
  ) {}

  init() {
    if (this.hasHistory && this.canShowHistory) {
      this.initHistory();
    }
    this.sortDesc = this.initSort.map((item) => item);

    // init filtering if necessary
    this.uniques = this.filteringAttrs.reduce((obj, attr) => {
      const props = this.getUniqueProperties(this.data, attr);
      obj[attr] = props;
      return obj;
    }, {});
    for (const attr of this.filteringListAttrs) {
      const uniqueItems = new Set();
      this.data.forEach((item) => {
        if (Array.isArray(item[attr])) {
          item[attr].forEach((element) => {
            uniqueItems.add(element);
          });
        }
      });
      this.uniques[attr] = Array.from(uniqueItems);
    }
    this.filter = Object.fromEntries(
      Object.entries(this.uniques)
        .filter(([key, value]) => Array.isArray(value) && value.length > 0)
        .map(([key, value]) => [key, [...value]]),
    );
    for (const attr of this.filteringIntAttrs) {
      this.filter[attr] = false;
    }
    if (this.defaultFilter != null) {
      for (const key in this.defaultFilter) {
        this.filter[key] = this.defaultFilter[key];
      }
    }
    this.htmlData = this.sortFilterData(true);
    this.rawData = this.sortFilterData(false);
    this.calculateTotalPages();
  }

  historyStr(item: any) {
    return `${dateTimeOrNull(item.timestamp, true)} by ${item.username}`;
  }

  initHistory() {
    this.data = this.data.map((item) => {
      const lastUpdate =
        "update_history" in item &&
        Array.isArray(item.update_history) &&
        item.update_history.length > 0
          ? this.historyStr(item.update_history[0])
          : null;

      return {
        ...item,
        ["lastUpdate"]: lastUpdate,
      };
    });
  }

  getUniqueProperties(list: T[], name: string) {
    return [...new Set(list.map((item) => item[name]))];
  }

  calculateTotalPages() {
    this.totalPages = Math.ceil(this.htmlData.length / this.rowsPerPage);
    if (this.totalPages === 0) {
      this.totalPages = 1;
    }
  }

  changeRowsPerPage() {
    this.calculateTotalPages();
    this.currentPage = 1;
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  resetPagination() {
    this.calculateTotalPages();
    this.currentPage = 1;
  }

  reload() {
    this.htmlData = this.sortFilterData(true);
    this.rawData = this.sortFilterData(false);
    this.resetPagination();
  }

  get paginatedData() {
    const start = (this.currentPage - 1) * this.rowsPerPage;
    const end = +start + +this.rowsPerPage;
    return this.htmlData.slice(start, end);
  }

  get rawPaginatedData() {
    const start = (this.currentPage - 1) * this.rowsPerPage;
    const end = +start + +this.rowsPerPage;
    return this.rawData.slice(start, end);
  }

  switchSortOrder(newOrder: number[]) {
    if (this.canSwitchSortOrder) {
      if (newOrder.length !== this.sortBy.length) {
        throw new Error(
          "The length of newOrder must match the length of sortBy",
        );
      }
      this.sortBy = newOrder.map((index) => this.sortBy[index]);
      this.sortByNames = newOrder.map((index) => this.sortByNames[index]);
      this.initSort = newOrder.map((index) => this.initSort[index]);
      this.sortDesc = newOrder.map((index) => this.sortDesc[index]);

      this.reload();
    }
  }

  getSortNames() {
    return this.sortByNames.join(", ");
  }

  rotateSortState(name: string) {
    const id = this.sortBy.indexOf(name);
    if (this.sortDesc[id] === null) {
      this.sortDesc[id] = true;
    } else if (this.sortDesc[id] === true) {
      this.sortDesc[id] = false;
    } else {
      if (this.sortBy.length === 1) {
        this.sortDesc[id] = true;
      } else {
        this.sortDesc[id] = null;
      }
    }
    this.reload();
  }

  resetSort() {
    this.sortDesc = this.initSort;
    this.reload();
  }

  sortState(name: string) {
    const id = this.sortBy.indexOf(name);
    if (this.sortDesc[id] === null) {
      return "&#9711;";
    } else if (this.sortDesc[id] === false) {
      return "&#9650;";
    } else {
      return "&#9660;";
    }
  }

  sortAndFilterCustom(sortBy: string[], sortDesc: boolean[], filter: object) {
    if (sortBy.length !== sortDesc.length) {
      throw new Error("The length of sortDir must match the length of sortBy");
    }
    const fieldSorter =
      (fields: string[], sortDesc: boolean[]) => (a: any, b: any) => {
        return fields
          .map((field: string, index: number) => {
            let dir = sortDesc[index] === null ? 0 : sortDesc[index] ? -1 : 1;

            if (this.dateFields.includes(field)) {
              const dateA = new Date(a[field]);
              const dateB = new Date(b[field]);
              return dateA > dateB ? dir : dateA < dateB ? -dir : 0;
            } else if (this.stringFields.includes(field)) {
              return (
                a[field].localeCompare(b[field], undefined, {
                  sensitivity: "base",
                }) * dir
              );
            } else {
              return a[field] > b[field] ? dir : a[field] < b[field] ? -dir : 0;
            }
          })
          .reduce((prev, next) => (prev ? prev : next), 0);
      };

    const sorted = this.data.sort(fieldSorter(sortBy, sortDesc));

    if (this.filteringAttrs.length > 0 || filter) {
      var filtered = sorted.filter((item) => {
        return Object.keys(filter).every((key) => {
          if (Array.isArray(item[key])) {
            return item[key].some((element) => filter[key].includes(element));
          } else {
            if (Array.isArray(filter[key])) {
              return filter[key].includes(item[key]);
            } else if (typeof filter[key] === "boolean") {
              return !(item[key] === 0 && filter[key]);
            }
          }
        });
      });
    } else {
      var filtered = sorted.map((item) => item);
    }

    return filtered;
  }

  formatDataList(data: T[]) {
    return data.map((item) => {
      const newItem = { ...item };
      this.dateFields.forEach((field) => {
        if (newItem[field]) {
          const value = newItem[field];
          newItem[field] = dateTimeOrNull(value, false);
        }
      });
      this.dateTimeFields.forEach((field) => {
        if (newItem[field]) {
          const value = newItem[field];
          newItem[field] = dateTimeOrNull(value, true);
        }
      });
      return newItem;
    });
  }

  sortFilterData(format: boolean = true) {
    var filtered = this.sortAndFilterCustom(
      this.sortBy,
      this.sortDesc,
      this.filter,
    );
    if (this.queryKey && this.queryFilter !== "") {
      filtered = filtered.filter((item) => {
        const valueToCheck = String(item[this.queryKey]).toLowerCase();
        return valueToCheck.includes(this.queryFilter.toLowerCase());
      });
    }

    if (format) {
      return this.formatDataList(filtered);
    } else {
      return filtered;
    }
  }

  startEditing(editId: number) {
    this.editingId = editId;
    this.editedField = this.getEditedField(editId);
  }

  stopEditing() {
    this.editingId = undefined;
    this.editedField = undefined;
  }

  getEditedField(index: number) {
    const paginatedStart = (this.currentPage - 1) * this.rowsPerPage;
    const member = this.rawData[paginatedStart + index];
    var editedField = Object.keys(member)
      .filter(
        (key) => this.editable.length === 0 || this.editable.includes(key),
      )
      .reduce((obj, key) => {
        obj[key] = member[key];
        return obj;
      }, {});
    for (const key of this.dateTimeFields) {
      if (editedField.hasOwnProperty(key)) {
        const value = editedField[key];
        editedField[key] = value ? parseDatetimeToLocal(value) : null;
      }
    }
    return editedField;
  }

  canEdit(fieldName: string, member: object) {
    return true;
  }

  maybeInt(value: any) {
    const intValue = parseInt(value);
    return isNaN(intValue) ? value : intValue;
  }

  handleFiltering(event, attr: string) {
    // find checkbox
    if (event.target.tagName === "LABEL") {
      var checkbox = event.target.querySelector('input[type="checkbox"]');
    } else if (event.target.tagName === "SPAN") {
      var checkbox = event.target
        .closest("label")
        .querySelector('input[type="checkbox"]');
    } else {
      var checkbox = event.target;
    }
    if (checkbox) {
      // if already selected -> reset
      if (
        Array.isArray(this.filter[attr]) &&
        this.filter[attr].length === 1 &&
        this.filter[attr][0] === this.maybeInt(checkbox.value)
      ) {
        this.filter[attr] = this.uniques[attr].map((item) =>
          this.maybeInt(item),
        );
      } else {
        // else select only this
        this.filter[attr] = [this.maybeInt(checkbox.value)];
      }
    }
  }

  getLegend(
    thisName: string,
    switchFunction: string = "switchSortOrder([1, 0])",
  ) {
    const names = this.getSortNames();
    let legend = "";
    if (this.filteringAttrs.length + this.filteringListAttrs.length > 0) {
      legend += `
        <p>
          <strong>Filtering: </strong>
          <span>Holding "shift" key will select <em>only</em> given item.</span><br />
          <span>Doing this again will reset filter and select all items.</span>
        </p>
      `;
    }

    if (this.defaultFilter != null) {
      legend += `<p><strong>Default filter: </strong><br />`;
      for (const key in this.defaultFilter) {
        const key_name = this.fieldMapper[key] ?? key;
        legend += `<strong>${key_name}:</strong> ${this.defaultFilter[key].join(
          ", ",
        )}<br />`;
      }
      legend += `</p>`;
    }

    if (this.sortBy.length > 1) {
      if (this.canSwitchSortOrder) {
        var switchButton = `
          <button @click="${thisName}.${switchFunction}">Switch</button>
        `;
      } else {
        var switchButton = "";
      }
      legend += `
        <p>
          <strong>Sort order: </strong> ${names}
          ${switchButton}
        </p>
        <p>&#9660;: descending; &#9650;: ascending; &#9711;: do not sort</p>
        <p>
          <button @click="${thisName}.resetSort()">Reset sort</button>
        </p>
      `;
    }
    return legend;
  }

  toggleHistory() {
    if (this.hasHistory && this.canShowHistory) {
      this.showingHistory = !this.showingHistory;
    }
  }

  getRowsSelectorTags(thisName: string) {
    let rowsSelector = `
      <span>
        <label>Rows per page:</label>
        <select
          x-model="${thisName}.rowsPerPage"
          @change="${thisName}.changeRowsPerPage()"
        >
          <template x-for="option in ${thisName}.rowsPerPageOptions">
            <option
              x-text="option"
              :selected="option==${thisName}.rowsPerPage"
            ></option>
          </template>
        </select>
      </span>
    `;
    if (this.hasHistory && this.canShowHistory) {
      rowsSelector += `
        <span class='history'>
          <button
            @click="${thisName}.toggleHistory()"
          >Toggle history</button>
        </span>
      `;
    }
    return rowsSelector;
  }

  getPaginationControlTags(thisName: string) {
    return `
      <button
        @click="${thisName}.prevPage()"
        :disabled="${thisName}.currentPage === 1"
      >
        &#9664;
      </button>
      <span
        >Page <span x-text="${thisName}.currentPage"></span> of
        <span x-text="${thisName}.totalPages"></span
      ></span>
      <button
        @click="${thisName}.nextPage()"
        :disabled="${thisName}.currentPage === ${thisName}.totalPages"
      >
        &#9654;
      </button>
    `;
  }

  getIntFilteringTags(thisName: string, attr: string) {
    return `
      <button
        @click="${thisName}.filter['${attr}'] = !${thisName}.filter['${attr}']"
        class="dropdown-toggle"
        :class="{ 'passive': !${thisName}.filter['${attr}'] }"
        x-effect="await ${thisName}.reload();"
      >
        Only # > 0
      </button>
    `;
  }

  getFilteringTags(
    thisName: string,
    attr: string,
    name: string,
    modelType: string = "",
    itemLookup: string = "",
  ) {
    if (itemLookup === "") {
      var itemName = "item";
    } else {
      var itemName = `${itemLookup}[item]`;
    }
    return `
      <div class="dropdown" x-data="{ open: false }">
        <button @click="open = !open" class="dropdown-toggle">
          Filter by ${name}
        </button>
        <div
          x-show="open"
          @click.away="open = false"
          class="dropdown-menu"
          x-effect="await ${thisName}.reload();"
        >
          <template x-for="item in ${thisName}.uniques['${attr}']">
            <label
              @click.shift.debounce.50ms="${thisName}.handleFiltering($event, '${attr}')"
            ><input
                type="checkbox"
                x-model${modelType}="${thisName}.filter['${attr}']"
                :disabled="${thisName}.uniques['${attr}'].length < 2"
                :value="item" />
              <span x-text="${itemName}"></span
            ></label>
          </template>
        </div>
      </div>
    `;
  }
}
