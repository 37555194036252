import { createMemo, Show } from "solid-js";
import { ApiConnector } from "../apiConnector";
import { Patient } from "../patient";
import { floatRounding } from "../utils";
import { CellDateEditable } from "./uiKit/table/CellDateEditable";
import { CellEditButtons } from "./uiKit/table/CellEditButtons";
import { CellTextEditable } from "./uiKit/table/CellTextEditable";
import { HeaderSort } from "./uiKit/table/HeaderSort";
import { Table } from "./uiKit/table/Table";

export function BasicMedicalTable(props: {
  patient: Patient;
  api: ApiConnector;
}) {
  const model = createMemo(() => props.patient.basicMed);

  return (
    <Table
      model={model()}
      columns={[
        {
          header1: (
            <HeaderSort
              model={model()}
              attr="measurement_datetime"
              name="Measured"
            />
          ),
          cell: (item, index) => (
            <CellDateEditable
              attr="measurement_datetime"
              index={index}
              model={model()}
              type="datetime-local"
            />
          ),
        },
        {
          header1: (
            <HeaderSort model={model()} attr="measurement_type" name="Type" />
          ),
          cell: (item) => item.measurement_type,
        },
        {
          header1: "Value",
          cell: (item, index) => (
            <CellTextEditable
              attr="measurement_value"
              index={index}
              model={model()}
              displayValue={floatRounding(item.measurement_value)}
              width={50}
            />
          ),
        },
        { header1: "Unit", cell: (item) => item.measurement_unit },
        {
          header1: "Comment",
          cell: (item, index) => (
            <CellTextEditable
              attr="comment"
              index={index}
              model={model()}
              width={50}
            />
          ),
        },
        {
          header1: "Edit",
          cell: (item, index) => (
            <>
              <Show when={!model().notEditable.includes(item.measurement_type)}>
                <CellEditButtons
                  model={model()}
                  index={index}
                  onConfirm={(editedField) => {
                    props.patient.updateItem(
                      "medicaldata",
                      item.uuid,
                      editedField,
                      model().dateTimeFields,
                    );
                  }}
                />
              </Show>
              <Show when={model().notEditable.includes(item.measurement_type)}>
                &nbsp;(auto-computed)
              </Show>
            </>
          ),
        },
        props.api.amIAdmin && {
          header1: "Delete",
          cell: (item) => (
            <>
              <Show when={!model().notEditable.includes(item.measurement_type)}>
                <button
                  onClick={() => {
                    props.patient.deleteItem("medicaldata", item.uuid);
                  }}
                >
                  Delete
                </button>
              </Show>
              <Show when={model().notEditable.includes(item.measurement_type)}>
                &nbsp;(auto-computed)
              </Show>
            </>
          ),
        },
        props.patient.vitals.showingHistory && {
          header1: "Added",
          cell: (item) => item.created_at,
        },
        props.patient.vitals.showingHistory && {
          header1: "Last edit",
          cell: (item) => item.lastUpdate,
        },
      ]}
    />
  );
}
