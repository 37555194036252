export function parseDatetimeToLocal(datetime: Date) {
  datetime = new Date(datetime);
  const localDatetime = new Date(
    datetime.getTime() - datetime.getTimezoneOffset() * 60000,
  );
  return localDatetime.toISOString().slice(0, 19).replace("T", " ");
}

export function dateTimeISOString(datetimeStr?: string) {
  var datetime = datetimeStr == null ? new Date() : new Date(datetimeStr);
  return datetime.toISOString();
}

export function dateTimeOrNull(
  dateString: string,
  isDateTime: boolean = true,
  locale: string = "sk-SK",
) {
  if (dateString === null || dateString === undefined || dateString === "") {
    return null;
  }
  const date = new Date(dateString);
  if (!isNaN(date.getTime())) {
    return Intl.DateTimeFormat(
      locale,
      isDateTime
        ? {
            dateStyle: "short",
            timeStyle: "medium",
          }
        : {},
    ).format(date);
  } else {
    return null;
  }
}

export function floatRounding(value: number | string, decimals: number = 3) {
  if (typeof value === "number") {
    return value.toFixed(decimals);
  }
  return value;
}

export function getObjectFromArray(options: string[] | number[]) {
  return options.reduce((acc, item) => {
    acc[item] = item;
    return acc;
  }, {});
}
