import { ApiConnector } from "../apiConnector";
import { DataModel } from "./DataModel";

export class APIDataModel<T extends object = any> extends DataModel<T> {
  paginated = false;
  apiTotalPages: number = null;
  apiTotalItems: number = null;
  apiPage = 1;
  firstPage = false;
  fullyLoaded = false;

  constructor(
    public api: ApiConnector,
    public pageSize: number = null,
  ) {
    super([]);
    if (pageSize) {
      this.rowsPerPage = pageSize;
    }
  }

  calculateTotalPages() {
    if (!this.paginated || (this.paginated && this.fullyLoaded)) {
      super.calculateTotalPages();
    } else {
      this.totalPages = this.apiTotalPages;
    }
  }
}
