import { JSX, Show } from "solid-js";

export function QuickLookButton(props: {
  children?: JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  processing?: boolean;
}) {
  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      class="heartcore-button-small"
    >
      <Show when={props.processing}>
        <img
          src="/static/imgs/sq_spin.png"
          class="spinner"
          style="width: 20px; height: 20px"
        />
      </Show>
      <Show when={!props.processing}>{props.children}</Show>
    </button>
  );
}
